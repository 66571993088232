export const handleNavigation = ({
  config,
  wixCodeApi,
  isPreview,
  wixSdkAdapter,
  showUserMessage,
}) => {
  if (
    config.isFeatureEnabled ||
    isPreview ||
    wixSdkAdapter.isDemoMode() ||
    wixSdkAdapter.isTemplateMode()
  ) {
    const bookingsRelativeUrl = getBookingsRelativeUrl(config, wixCodeApi);
    wixCodeApi.location.to &&
      wixCodeApi.location.to(`${bookingsRelativeUrl}/book`);
  } else {
    showUserMessage();
  }
};

const getBookingsRelativeUrl = (config, wixCodeApi): string => {
  const fullBookingsUrl = config.serviceDto.fullUrl;
  const baseUrl = wixCodeApi.location.baseUrl;
  if (baseUrl) {
    const urlParts = fullBookingsUrl.split(baseUrl);
    return urlParts.length > 1 ? urlParts[1] : fullBookingsUrl;
  }
  return fullBookingsUrl;
};
