import { CatalogServiceDto } from '@wix/bookings-uou-types/dist/src';

export interface HeaderViewModel {
  title: string;
  imageUri: string;
  isBookable: boolean;
}

export const headerViewModelFactory = (
  serviceDto: CatalogServiceDto,
  isBookable: boolean,
): HeaderViewModel => {
  return {
    title: serviceDto.info.name,
    imageUri: serviceDto.info.images[0]?.relativeUri,
    isBookable,
  };
};
