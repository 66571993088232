import { ServicePaymentDto } from '@wix/bookings-uou-types/dist/es/src';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { CurrencyFormatName } from '@wix/bookings-uou-mappers/dist/types/price/price-mapper';

export class PaymentDtoMapper {
  constructor(private regionalSettings: string) {}

  public priceText(
    paymentDto: ServicePaymentDto,
    currencyDisplay?: CurrencyFormatName,
  ): string {
    const { isFree, priceText, currency, price } = paymentDto;
    const shouldShowPrice = !isFree && price;

    if (priceText) {
      return priceText;
    }

    if (shouldShowPrice) {
      return PriceUtils.getFormattedCurrency({
        price,
        currency,
        locale: this.regionalSettings,
        currencyDisplay,
      });
    }

    return '';
  }
}
